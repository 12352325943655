import { Component, OnDestroy } from '@angular/core';
//import { NbAuthService } from '../services/auth.service';
import { NbAuthService } from'@nebular/auth';

@Component({
  selector: 'nb-auth',
  styleUrls: ['./auth.component.scss'],
  template: `
    <nb-layout>
      <nb-layout-column>
        <nb-card>
          <nb-card-header style="background-color:rgb(255, 102, 0);font-family:Roboto; font-weight:normal; font-size: 30px;">
          <div><img src="assets/images/novaseeklogo.png" width="159px"/>  &nbsp;&nbsp;<span style="font-size:16px;">Admin</span></div>
          </nb-card-header>
          <nb-card-body>
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-12">
              <router-outlet></router-outlet>
            </div>
          </nb-card-body>
        </nb-card>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class NgxAuthComponent implements OnDestroy {

  subscription: any;

  authenticated = false;
  token = '';

  // showcase of how to use the onAuthenticationChange method
  constructor(protected auth: NbAuthService) {

    this.subscription = auth.onAuthenticationChange()
      .subscribe((authenticated: boolean) => {
        this.authenticated = authenticated;
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
