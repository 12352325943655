import { Component, ViewEncapsulation } from '@angular/core';
import { SpecimenService } from '../../../@core/model/specimen.service';
import { Narrative } from '../../../@core/model/specimen';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-modal',
  styleUrls: ['./modalnarratives.component.css'],
  templateUrl: './modalnarratives.component.html',
  encapsulation: ViewEncapsulation.None,

})
export class ModalNarrativesComponent {
    modalHeader: string;
    currentpage: number; //set in calling method
    page: number = 1;  //number in paginatin to display first
    public isLoading = true ;
    private statusMessage = 'Loading...';

    private patient_code = '';  //patient code set in callling method
    public collectioncount = 10; //pagination collection counter

    public currNarrative: string;
    public narrativedate: string;
    public documenttype: string;
    public documentname: string;
    public documentstatus: string;

    private warehouse_narratives: Narrative[]= [];

  constructor(private activeModal: NgbActiveModal, private specimenService: SpecimenService) {
  }

  ngOnInit() {
     //test code, patient code is set outside
     //console.log(this.patient_code);
    if (this.patient_code == '') {
        this.patient_code = 'KJZSQLH';
    }

    //console.log(this.currentpage)
    this.specimenService.getPatientNarratives(this.patient_code).subscribe((res: Narrative[]) => {
        this.page = this.currentpage;
        this.warehouse_narratives = res;
        for (const data of res) {
            data.date = data.date.slice(0, 10);
          }
        this.collectioncount = this.warehouse_narratives.length * 10;
        this.pageChanged(this.page);
        this.isLoading = false;
        },
        () => {
          this.isLoading = true;
    });
  }
  pageChanged(page) {
    this.currNarrative = this.warehouse_narratives[page-1].document_body;
    this.narrativedate = this.warehouse_narratives[page-1].date;
    this.documentname = this.warehouse_narratives[page-1].document_name;
    this.documentstatus = this.warehouse_narratives[page-1].document_status;
    this.documenttype = this.warehouse_narratives[page-1].document_type;
    //console.log('Page changed: ' + page);
  }
  closeModal() {
    this.activeModal.close();
  }
}
