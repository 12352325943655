import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';

import { ReportService } from './report.service';
import { SpecimenService } from './specimen.service';
import { StateService } from './state.service';
import { UserService } from './users.service';

const SERVICES = [
  SpecimenService,
  UserService,
  StateService,
  ReportService,
  MomentModule,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class ModelModule {
  static forRoot(): ModuleWithProviders<ModelModule> {
    return {
      ngModule: ModelModule,
      providers: [
        ...SERVICES,
      ],
    } as ModuleWithProviders;
  }
}
