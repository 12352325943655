import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(
    private analytics: AnalyticsService,
    private iconLibraries: NbIconLibraries
  ) {
    // register legacy nebular-icons as a pack for Nebular (temporary?)
    // this allows us to continue using old icon set while migrating to eva-icons
    this.iconLibraries.registerFontPack('nebular', { iconClassPrefix: 'nb' });
    this.iconLibraries.setDefaultPack('nebular');
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
  }
}
