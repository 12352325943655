import { Component, ViewEncapsulation, OnInit, Input, Output,
         OnChanges, EventEmitter, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatTableDataSource, MatSort,MatSortable } from '@angular/material';
import { ModalNarrativesComponent } from './modalnarratives.component';

import { SpecimenService } from '../../../@core/model/specimen.service';
import { PatientEncounter, LabTest, Problem, Medication, Procedure, 
         Specimen, Narrative } from '../../../@core/model/specimen';
import { WarehouseFacility } from '../../../@core/model/warehouse-facility';

@Component({
  selector: 'ngx-modal',
  styleUrls: ['./modal.component.css'],
  templateUrl: './modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {


  modalHeader = 'Patient Details';

  patient_code = '';  //patient code search
  demographics: boolean;
  labresults: boolean;
  problems: boolean;
  medications: boolean;
  procedures: boolean;
  specimens: boolean;
  narratives: boolean;

  private warehouse_encounter: PatientEncounter;
  private warehouse_labresults: LabTest[]= [];
  private warehouse_problems: Problem[]= [];
  private warehouse_medications: Medication[]= [];
  private warehouse_procedures: Procedure[]= [];
  private warehouse_specimens: Specimen[]= [];
  private warehouse_narratives: Narrative[]= [];
  public site = '';

  private currenttab: string;
  public isLoading1 = true ;
  public validCode = false;
  private statusMessage = 'Loading...';
  private notFoundMessage;

  //sort see https://stackoverflow.com/questions/48001006/angular-material-distinct-mat-sort-on-multiple-tables
  //labresults
  @ViewChild('labTableSort', { static: true }) public labTableSort: MatSort;
  displayedColumns2 = ['date', 'vocabulary', 'description', 'value', 'uom'];
  labDataSource = new MatTableDataSource();
  public isLoading2 = true ;
  //problems

  @ViewChild('probTableSort', { static: true }) public probTableSort: MatSort;
  displayedColumns3 = ['date', 'type',  'vocabulary', 'description'];
  probDataSource = new MatTableDataSource();
  public isLoading3 = true ;

  //medication
  @ViewChild('medTableSort', { static: true }) public medTableSort: MatSort;
  displayedColumns4 = ['date', 'vocabulary',  'description', 'quantity', 'frequency'];
  medDataSource = new MatTableDataSource();
  public isLoading4 = true ;
  //procedure
  @ViewChild('procTableSort', { static: true }) public procTableSort: MatSort;
  displayedColumns5 = ['date', 'vocabulary', 'description'];
  procDataSource = new MatTableDataSource();
  public isLoading5 = true ;
  //specimen
  @ViewChild('specTableSort', { static: true }) public specTableSort: MatSort;
  displayedColumns6 = ['date', 'specimen_type', 'additives', 'date_discard', 'quantity', 'site', 'warehouse_specimen_id', 'shipment_id'];
  specDataSource = new MatTableDataSource();
  public isLoading6 = true ;

  @ViewChild('narrTableSort', { static: true }) public narrTableSort: MatSort;
  displayedColumns7 = ['date', 'document_type', 'document_name', 'document_status'];
  narrDataSource = new MatTableDataSource();
  public isLoading7 = true ;

  constructor(private activeModal: NgbActiveModal, private specimenService: SpecimenService, private modalService: NgbModal) {
    //get the first one
    this.warehouse_encounter = new PatientEncounter();
  }

  ngOnInit() {
    this.demographics = false;
    this.labresults = false;
    this.problems = false;
    this.medications = false;
    this.procedures = false;
    this.specimens = false;
    this.narratives = false;

    this.currenttab = 'demographics';
    this.specTableSort.sort(<MatSortable>{
        id: 'date',
        start: 'desc'
      }
    );

    //test code, patient code is set outside
    if (this.patient_code == 'test') {
      //this.patient_code = 'Q3PKPM';
      this.patient_code = 'KJZSQLH';
    }

    this.onChange('demographics');

  }

  ngAfterViewInit() {
    this.labDataSource.sort = this.labTableSort;
    this.probDataSource.sort = this.probTableSort;
    this.medDataSource.sort = this.medTableSort;
    this.procDataSource.sort = this.procTableSort;
    this.specDataSource.sort = this.specTableSort;
    this.narrDataSource.sort = this.narrTableSort;

  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    if (this.currenttab == 'labresults') {
      this.labDataSource.filter = filterValue;
    }
    else if (this.currenttab == 'problems') {
      this.probDataSource.filter = filterValue;
    }
    else if (this.currenttab == 'medications') {
      this.medDataSource.filter = filterValue;
    }
    else if (this.currenttab == 'procedures') {
      this.procDataSource.filter = filterValue;
    }
    else if (this.currenttab == 'specimens') {
      this.specDataSource.filter = filterValue;
    }
    else if (this.currenttab == 'narratives') {
        this.narrDataSource.filter = filterValue;
      }
  }

  onChange(tab: string) {
    this.currenttab = tab;

    if (this.currenttab == 'demographics') {
      if (!this.demographics) {
        //get demographics first
        this.specimenService.getPatientDemographics(this.patient_code).subscribe((res: PatientEncounter) => {
            console.log(res);
          this.warehouse_encounter = res;
          this.warehouse_encounter.dob =   ((new Date()).getFullYear() -  (new Date(this.warehouse_encounter.dob)).getFullYear()).toString();
          this.isLoading1 = true;
          this.demographics = true;
          //console.log("Patient: ",this.warehouse_encounter)
          this.currenttab = 'demographics';
          this.isLoading1 = false;
          if (this.warehouse_encounter.patient_code == '') {
              //console.log("invalid search code!");
              this.site = 'N/A';
              this.warehouse_encounter.gender = '';
              this.warehouse_encounter.dob = '';
              this.warehouse_encounter.race = '';
              this.warehouse_encounter.hispanic = '';
              this.warehouse_encounter.state = ''; this.validCode = false;
              this.validCode = false;
              this.notFoundMessage = 'Patient Code: ' + this.patient_code + ' Not Found!';
              this.statusMessage = this.notFoundMessage;
          }
          else {
            this.validCode = true;
            this.specimenService.getWarehouseFacility(this.warehouse_encounter.warehouse_facility_id).subscribe((res: WarehouseFacility) => {
                this.site = res.name;
                this.isLoading1 = false;
            },
            () => {
                this.isLoading1 = true;
            });
          }
        },
        () => {
          this.isLoading1 = true;
        });
      }
    }
    else if (this.currenttab == 'labresults') {
      if (this.validCode && !this.labresults) {
        this.statusMessage = 'Loading...';
        this.isLoading2 = true;
        this.labresults = true;
        this.specimenService.getPatientLabResults(this.patient_code).subscribe((res: LabTest[]) => {
          for (const data of res) {
            data.date = data.date.slice(0, 10);
            data.vocabulary = data.code_type + ' ' + data.code;
          }
          this.labDataSource.data = res;
          this.isLoading2 = false;
        },
        () => {
          this.isLoading2 = true;
        });
      }
    }
    else if (this.currenttab == 'problems') {
      if (this.validCode && !this.problems) {
        this.isLoading3 = true;
        this.problems = true;
        this.specimenService.getPatientProblems(this.patient_code).subscribe((res: Problem[]) => {
          //console.log(res);
        for (const data of res) {
          data.date = data.date.slice(0, 10);
          data.vocabulary = data.code_type + ' ' + data.code;
        }
        this.probDataSource.data = res;
        //console.log(res);
        this.isLoading3 = false;
        },
        () => {
          this.isLoading3 = true;
        });
      }
      else {
      }
    }
    else if (this.currenttab == 'medications') {
      if (this.validCode && !this.medications) {
        this.isLoading4 = true;
        this.medications = true;
        this.specimenService.getPatientMedications(this.patient_code).subscribe((res: Medication[]) => {
        for (const data of res) {
          data.date = data.date.slice(0, 10);
          data.vocabulary = data.code_type + ' ' + data.code;
        }
        this.medDataSource.data = res;
        this.isLoading4 = false;
        },
        () => {
          this.isLoading4 = true;
        });
      }
    }
    else if (this.currenttab == 'procedures') {
      if (this.validCode && !this.procedures) {
        this.isLoading5 = true;
        this.procedures = true;
        this.specimenService.getPatientProcedures(this.patient_code).subscribe((res: Procedure[]) => {
        for (const data of res) {
          data.date = data.date.slice(0, 10);
          data.vocabulary = data.code_type + ' ' + data.code;
        }
        this.procDataSource.data = res;
        this.isLoading5 = false;
        },
        () => {
          this.isLoading5 = true;
        });
      }
    }
    else if (this.currenttab == 'specimens') {
      if (this.validCode && !this.specimens) {
        this.specimens = true;
        this.isLoading6 = true;
        this.specimenService.getPatientSpecimens(this.patient_code).subscribe((res: Specimen[]) => {
          for (const data of res) {
            data.date = data.date.slice(0, 10);
            data.date_discard = data.date_discard.slice(0, 10);
          }
          this.specDataSource.data = res;
          this.isLoading6 = false;
        },
        () => {
          this.isLoading6 = true;
        });
      }
    }
    else if (this.currenttab == 'narratives') {
        if (this.validCode && !this.narratives) {
          this.isLoading7 = true;
          this.narratives = true;
          this.specimenService.getPatientNarratives(this.patient_code).subscribe((res: Narrative[]) => {
          for (const data of res) {
            data.date = data.date.slice(0, 10);
          }
          this.warehouse_narratives = res;
          this.narrDataSource.data = res;
          //console.log(res);
          this.isLoading7 = false;
          },
          () => {
            this.isLoading7 = true;
          });
        }
      }
  }
  selectRow(row) {
    //alert(row['document_name']);
    this.showNarrativeDialog("Narrative Detail", row['document_name']);
  }

  showNarrativeDialog(title: string, document_name: string) {
    const activeModal = this.modalService.open(ModalNarrativesComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
      windowClass: 'hugeModal2',
    });
    var page = 0;
    activeModal.componentInstance.modalHeader = title;
    activeModal.componentInstance.patient_code = this.patient_code;
    for (var i=0; i< this.narrDataSource.data.length; i++) {
        if (this.warehouse_narratives[i].document_name == document_name) {
            page = i;
            break;
        }
    }
    activeModal.componentInstance.currentpage = page+1;
  }

  closeModal() {
    this.activeModal.close();
  }
}

