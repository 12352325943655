import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, interval, of } from 'rxjs';
import { skipWhile, switchMap, take, tap } from 'rxjs/operators';

import { AdminConfig } from '../model/config';
import { LoginUser } from './login-user';
import { UserCredential } from '../../@core/model/user-credential';
import { UserAuth } from './user-auth';
import { UserAuthSession } from './user-auth-session';

@Injectable()
export class AuthenticationService {
  // TODO separate session service from login user?
  sessionUser$ = new BehaviorSubject<UserAuthSession>(this.getSessionUser());

  private config: AdminConfig = new AdminConfig();

  constructor(private httpClient: HttpClient, private router: Router) {
    // start polling to check if session has timed out
    this.startPollSessionTimeout();
  }

  createSession(userCredential: UserCredential) {
    const sessionUrl = this.config.baseUrl + 'session';
    return this.httpClient.post<UserAuthSession>(sessionUrl, userCredential).pipe(tap(user => this.saveSessionUser(user)));
  }

  // THIS LEGACY RESOURCE IS DEPRECATED
  createGoogleSession(token: any) {
    const sessionUrl = this.config.baseUrl + 'google_session';
    return this.httpClient.post<UserAuthSession>(sessionUrl, token).pipe(tap(user => this.saveSessionUser(user)));
  }

//  THIS LEGACY RESOURCE IS DEPRECATED & NO LONGER FUNCTIONAL
//  createLegacySession(userdata: UserAuth) {
//    const sessionUrl = this.config.baseUrl + 'session';
//    return this.httpClient.post<UserAuthSession>(sessionUrl, userdata).pipe(tap(user => this.saveSessionUser(user)));
//  }

  deleteSession() {
    // shouldn't happen but clean up storage anyway
    if (!this.getSessionUser() || !this.getSessionUser().client_token) {
      return of(true).pipe(tap(() => this.clearSessionStorage()));
    }

	// sign out of the google session
	//window.gSignOut(); // TODO figure out how to call this from Angular?!?
	
    const sessionUrl = this.config.baseUrl + 'session/' + this.getSessionUser().client_token;

    return this.httpClient.delete(sessionUrl).pipe(tap(() => this.clearSessionStorage()));
  }

  setLoginUser(userdata: LoginUser) {
    sessionStorage.setItem('LoginUser', JSON.stringify(userdata));
  }

  getLoginUser(): LoginUser {
    return JSON.parse(sessionStorage.getItem('LoginUser')) || new LoginUser();
  }

  isAuthenticated(): boolean {
    let userSession: UserAuthSession;
    userSession = JSON.parse(sessionStorage.getItem('UserSession'));

    if (userSession === null) {
      return false;
    }

    const now = moment(new Date().toISOString(), 'YYYY-MM-DD HH:mm:ss');
    const expires = moment(userSession.date_expires, 'YYYY-MM-DD HH:mm:ss');

    return now < expires;
  }

  setSessionExpired(isExpired) {
    sessionStorage.setItem('SessionExpired', isExpired);
  }

  isSessionExpired() {
    if (sessionStorage.getItem('SessionExpired') == null) {
      this.setSessionExpired(false);
    }

    return JSON.parse(sessionStorage.getItem('SessionExpired'));
  }

  private saveSessionUser(userSession: UserAuthSession) {
    sessionStorage.setItem('UserSession', JSON.stringify(userSession));
    this.sessionUser$.next(userSession);
  }

  private getSessionUser(): UserAuthSession {
    return JSON.parse(sessionStorage.getItem('UserSession'));
  }

  private clearSessionStorage() {
    sessionStorage.clear();
    this.sessionUser$.next(null);
  }

  private startPollSessionTimeout(): void {
    this.sessionUser$
      .pipe(
        switchMap(user => {
          if (user) {
            return interval(1000).pipe(
              skipWhile(this.isAuthenticated),
              take(1)
            );
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe(() => {
        console.debug('Session timed out');
        this.clearSessionStorage();
        this.setSessionExpired(true);
        this.router.navigate(['auth/login']);
      });
  }
}
