export class  PatientEncounter {
    className: string;
    warehouse_encounter_id: string;
    warehouse_facility_id: string;
    patient_code: string;
    state: string;
    gender: string;
    race: string;
    hispanic: string;
    dob: string;
    zip: string;
    retrospective: string;
    revoked: string;
    date_created: string;
    date_updated: string;
    encounter_hashkey: string;
    age: string;
}

export class LabTest {
    className: string;
    warehouse_labtest_id: string;
    warehouse_encounter_id: string;
    patient_code: string;
    warehouse_specimen_id: string;
    date: string;
    code: string;
    code_type: string;
    vocabulary: string;
    description: string;
    value: string;
    value_float: string;
    uom: string;
    retrospective: string;
    date_created: string;
    date_updated: string;

    constructor()
    {
        this.date = '';
        this.code_type = '';
        this.code = '';
        this.description = '';
        this.value = '';
        this.uom = '';
        this.vocabulary = '';
    }
}

export class Problem {
    className: string;
    warehouse_problem_id: string;
    warehouse_encounter_id: string;
    patient_code: string;
    external_id: string;
    type: string;
    date: string;
    code: string;
    code_type: string;
    description: string;
    vocabulary: string;
    retrospective: string;
    date_created: string;
    date_updated: string;
}

export class Medication {
    className: string;
    warehouse_medication_id: string;
    warehouse_encounter_id: string;
    patient_code: string;
    external_id: string;
    date: string;
    code: string;
    code_type: string;
    vocabulary: string;
    description: string;
    quantity: string;
    dose: string;
    uom: string;
    frequency: string;
    route: string;
    retrospective: string;
    date_created: string;
    date_updated: string;
}

export class Procedure {
    className: string;
    warehouse_procedure_id: string;
    warehouse_encounter_id: string;
    patient_code: string;
    external_id: string;
    date: string;
    code: string;
    code_type: string;
    vocabulary: string;
    description: string;
    retrospective: string;
    date_created: string;
    date_updated: string;
}

export class Specimen {
    className: string;
    warehouse_specimen_id: string;
    warehouse_encounter_id: string;
    warehouse_facility_id: string;
    novaseek_specimen_id: string;
    patient_code: string;
    date: string;
    specimen_type: string;
    specimen_size: string;
    uom: string;
    additives: string;
    body_site: string;
    collection_method: string;
    specimen_role: string;
    location: string;
    infectious: string;
    retrospective: string;
    date_created: string;
    date_updated: string;
    native_specimen_type: string;
    encounter_hashkey: string;
    specimen_hashkey: string;
    site: string;
    shipment_id: string;
    date_discard: string;

    constructor()
    {
        this.className  = '';
        this.warehouse_specimen_id = '';
        this.warehouse_encounter_id  = '';
        this.warehouse_facility_id  = '';
        this.novaseek_specimen_id  = '';
        this.patient_code  = '';
        this.date  = '';
        this.specimen_type  = '';
        this.specimen_size  = '';
        this.uom  = '';
        this.additives  = '';
        this.body_site  = '';
        this.collection_method  = '';
        this.specimen_role  = '';
        this.location  = '';
        this.infectious  = '';
        this.retrospective  = '';
        this.date_created  = '';
        this.date_updated  = '';
        this.native_specimen_type  = '';
        this.encounter_hashkey  = '';
        this.specimen_hashkey  = '';
        this.site  = '';
        this.shipment_id  = '';
        this.date_discard  = '';

    }
}


export class Narrative {
    date: string;
    encounter_hashkey: string;
    date_updated: string;
    date_created: string;
    className: string;
    warehouse_encounter_id: string;
    warehouse_facility_id: string;
    document_name: string;
    document_status: string;
    warehouse_narrative_id: string;
    source_document_id: string;
    document_body: string;
    document_type: string;
    patient_code: string;

    constructor()
    {
        this.date  = '';
        this.encounter_hashkey = '';
        this.date_updated  = '';
        this.date_created  = '';
        this.className  = '';
        this.warehouse_encounter_id  = '';
        this.warehouse_facility_id  = '';
        this.document_name  = '';
        this.document_status  = '';
        this.warehouse_narrative_id  = '';
        this.source_document_id  = '';
        this.document_body  = '';
        this.document_type  = '';
        this.patient_code  = '';
    }
}
