import { Site } from './site';

export class User {
  className: string;
  user_id: string;
  name: string;
  email: string;
  password: string;
  user_type: string;
  license_date: string;
  date_created: string;
  date_updated: string;
  force_passwd_reset: string;
  active: string;
  password_requirement_message: string;
  password_requirement_regex: string;
  lab_sites: Site[];
  organization: string;
  resetpassword?: User;
  portal?: any[];

  constructor() {
    this.className = '';
    this.user_id = '';
    this.name = '';
    this.email = '';
    this.password = '';
    this.user_type = '';
    this.license_date = '';
    this.date_created = '';
    this.date_updated = '';
    this.force_passwd_reset = '';
    this.active = '';
    this.password_requirement_message = '';
    this.password_requirement_regex = '';
    this.lab_sites = [];
  }
}
