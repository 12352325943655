import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AdminConfig } from '../model/config';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
    config: AdminConfig = new AdminConfig();

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

	    console.log("INTERCEPTING HttpRequest");

        const authReq = req.clone({
            withCredentials: true,
            setHeaders: {
                'api-key': this.config.apiKey,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
//            setParams: {
//                api_key: this.config.apiKey
//            }
        });

        return next.handle(authReq);
  }
}
