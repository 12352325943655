import { Component } from '@angular/core';
//import { version } from '../../../../../package.json';
declare var require: any

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Copyright <b><a href="https://www.novaseekresearch.com" target="_blank">Novaseek Research Inc.</a></b> {{year}}. All Rights Reserved. v{{version}}</span>
  `,
})
export class FooterComponent {
  public year = (new Date()).getFullYear();
  public version: string = require( '../../../../../package.json').version;
}
