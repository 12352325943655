import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from './@core/core.module';
import { NgxAuthBlockComponent } from './@theme/components/auth/auth-block/auth-block.component';
import { NgxAuthComponent } from './@theme/components/auth/auth.component';
import { NgxLoginComponent } from './@theme/components/auth/login/login.component';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './authentication.guard';
import { HttpInterceptor } from './@core/http/http-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbEvaIconsModule } from '@nebular/eva-icons';

// import { NgxRegisterComponent } from './@theme/components/auth/register/register.component';
// import { NgxLogoutComponent } from './@theme/components/auth/logout/logout.component';
// import { NgxRequestPasswordComponent } from './@theme/components/auth/request-password/request-password.component';
// import { NgxResetPasswordComponent } from './@theme/components/auth/reset-password/reset-password.component';
// import { NgxSearchInputComponent } from './@theme/components/search-input/search-input.component';

@NgModule({
  declarations: [
    AppComponent,
    NgxAuthComponent,
    NgxAuthBlockComponent,
    NgxLoginComponent
    // NgxRegisterComponent,
    // NgxRequestPasswordComponent,
    // NgxResetPasswordComponent,
    // NgxLogoutComponent,
    // NgxSearchInputComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    NbEvaIconsModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' },{ provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true }, AuthGuard]
})
export class AppModule {}
