import { User } from '../model/user';
import { UserAuth } from './user-auth';

export class LoginUser {

  name: string;
  fullname: string;
  role: string;
  picture: string;
  isAuthenticated: boolean;
  userAuth: UserAuth;
  user: User;

  constructor() {
    this.name = '';
    this.fullname = '';
    this.role = 'admin';
    this.picture = 'assets/images/user.png';
    (this.isAuthenticated = false), (this.userAuth = new UserAuth());
    this.user = new User();
  }

}
