export class AdminConfig {
  baseUrl: string; // api
  baseCDNRUrl: string; // cdrn
  baseLabUrl: string; // lab
  apiKey: string; // apikey

  constructor() {
    this.baseUrl = 'https://admin-api.novaseekresearch.com/admin/';
    this.baseCDNRUrl = 'https://cdnr.novaseekresearch.com/';
    this.baseLabUrl = 'https://lab.novaseekresearch.com/';
    this.apiKey = 'e00b2200-f389-4e89-ae54-ff2d3d27855e';
  }
}
