import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NbLogoutComponent, NbRegisterComponent, NbRequestPasswordComponent, NbResetPasswordComponent } from '@nebular/auth';

import { NgxAuthComponent } from './@theme/components/auth/auth.component';
import { NgxLoginComponent } from './@theme/components/auth/login/login.component';
import { AuthGuard } from './authentication.guard';

// import { NgxRegisterComponent } from './@theme/components/auth/register/register.component';

// import { NgxLogoutComponent } from './@theme/components/auth/logout/logout.component';
// import { NgxRequestPasswordComponent } from './@theme/components/auth/request-password/request-password.component';
// import { NgxResetPasswordComponent } from './@theme/components/auth/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: 'pages',
    canLoad: [AuthGuard],
    loadChildren: () => import('app/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'auth',
    component: NgxAuthComponent,
    children: [
      {
        path: '',
        component: NgxLoginComponent
      },
      {
        path: 'login',
        component: NgxLoginComponent
      },
      {
        path: 'register',
        // component: NgxRegisterComponent,
        component: NbRegisterComponent
      },
      {
        path: 'logout',
        // component: NgxLogoutComponent,
        component: NbLogoutComponent
      },
      {
        path: 'request-password',
        // component: NgxRequestPasswordComponent,
        component: NbRequestPasswordComponent
      },
      {
        path: 'reset-password',
        // component: NgxResetPasswordComponent,
        component: NbResetPasswordComponent
      }
    ]
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' }
];

const config: ExtraOptions = {
  useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
