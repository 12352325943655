import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuService, NbSearchService, NbSidebarService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthenticationService } from '../../../@core/authentication/authentication.service';
import { LoginUser } from '../../../@core/authentication/login-user';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { ModalComponent } from '../patient-detail/modal.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  private menuSubscription: Subscription;

  @Input()
  position = 'normal';

  // hack to be used as sub menu tag, idea taken from here: https://github.com/akveo/nebular/issues/426
  createdTimestamp = new Date().getTime().toString();
  inSearch = false;
  user: any;
  mydata: any;
  userMenu = [{ title: 'Log out', queryParams: { action: 'logout' } }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private authenticationService: AuthenticationService,
    private analyticsService: AnalyticsService,
    private search: NbSearchService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.getLoginUser();

	//console.log("!!! ngOnInit().this.authenticationService.getLoginUser() LoginUser: ");
	//console.table(this.authenticationService.getLoginUser());
	
    // don't like this nbMenuService and handling of clicks...
    this.menuSubscription = this.menuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === this.createdTimestamp.toString()),
        map(({ item: { queryParams } }) => queryParams.action)
      )
      .subscribe(action => {
        if (action === 'logout') {
          console.log('Menu click logout');
          this.logout();
        }
      });
  }

  ngOnDestroy(): void {
    this.menuSubscription.unsubscribe();
  }

  logout() {
    this.authenticationService.deleteSession().subscribe(() => {
      console.log('Logout');
//      this.router.navigate(['/']);
	this.router.navigateByUrl('/');
    });
  }

  toggleSidebar(): boolean {
	console.log("!!! toggleSidebar()");
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
	console.log("!! toggleSettings()");
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
	console.log("!!! goToHome()");
    this.menuService.navigateHome();
  }

  startSearch() {
	console.log("!!! startSearch()");
    this.analyticsService.trackEvent('startSearch');
    this.search.onSearchSubmit().subscribe(data => {
      if (this.inSearch === false) {
        this.showPatientDetailDialog(data.term.toString().trim().toUpperCase());
      }
      this.inSearch = true;
    });

    this.search.onSearchDeactivate().subscribe(() => {
      this.inSearch = false;
    });
  }

  showPatientDetailDialog(content: string) {
	console.log("showPatientDetailDialog()");
    const activeModal = this.modalService.open(ModalComponent, {
      size: 'lg',
      backdrop: 'static',
      container: 'nb-layout',
      windowClass: 'hugeModal'
    });

    activeModal.componentInstance.patient_code = content;
  }
}
