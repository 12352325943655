import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { AuthenticationService } from './@core/authentication/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivate() {

    console.log('Can activate?', this.authenticationService.isAuthenticated());

    if (this.authenticationService.isAuthenticated()) {
      return true;
    } else {
      this.authenticationService.setSessionExpired(true);
      this.router.navigate(['auth/login']);
    }
  }

  canActivateChild(): boolean {
    return this.canActivate();
  }

  // should check the url to be loaded in case this is used on other modules besides logging in
  // not being used at the moment since this should wait for login to complete
  canLoad(): boolean {

    console.log('Can load?', this.authenticationService.isAuthenticated());

    return this.authenticationService.isAuthenticated();
  }
}
