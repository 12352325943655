import { ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS } from '@nebular/auth';
import { getDeepFromObject } from '@nebular/auth';
import { NbAuthService } from '@nebular/auth';
import { takeWhile } from 'rxjs/operators';

import { AuthenticationService } from '../../../../@core/authentication/authentication.service';
import { LoginUser } from '../../../../@core/authentication/login-user';
import { UserAuth } from '../../../../@core/authentication/user-auth';
import { UserAuthSession } from '../../../../@core/authentication/user-auth-session';
import { User } from '../../../../@core/model/user';
import { UserCredential } from '../../../../@core/model/user-credential';
import { UserService } from '../../../../@core/model/users.service';

//import firebase from 'firebase/compat/app';
//import * as firebaseui from 'firebaseui'


@Component({
  selector: 'nb-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html'
})
export class NgxLoginComponent implements OnDestroy {
  showMessages: any = {};
  sessionExpired: any = false;
  errors: string[] = [];
  messages: string[] = [];
  submitted = false;

  loginForm = new FormGroup({
    email: new FormControl('', []),
    password: new FormControl('', [])
  });

//  loginForm = new FormGroup({
//    email: new FormControl('', [Validators.required, Validators.email]),
//    password: new FormControl('', [
//      Validators.required,
//      Validators.pattern(''),
//      Validators.minLength(this.getConfigValue('forms.validation.password.minLength')),
//      Validators.maxLength(this.getConfigValue('forms.validation.password.maxLength'))
//    ])
//  });

  private userCredential: UserCredential;
  private legacyUserCredential: UserAuth;
  private inView = true;
  private google_jwt = '';
  private google_profile;
  private firebaseConfig =  {
      apiKey: "AIzaSyDDAcbEChzxg-mL-XQsrFNqfBnJ2F3Fo5A",
      authDomain: "novaseek-cdnr-identity-service.firebaseapp.com",
    };
  private firebaseUiInstance;

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
//	private zone: NgZone // not needed?
  ) {	
	// expose the glogin function to javascript so the google auth functions can invoke this
	window['ngLoginComponent'] = {
//        zone: this.zone, // not needed?
        setGoogleJwt: (jwt) => this.setGoogleJwt(jwt),
		setGoogleProfile: (profile) => this.setGoogleProfile(profile),
        component: this
    };


    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.legacyUserCredential = new UserAuth();

    this.sessionExpired = authenticationService.isSessionExpired();

    // FIXME
    authenticationService.setSessionExpired(false);
    
    // NEW GOOGLE IDENTITY FUNCTIONS:
    // WORK IN PROGRESS - NOTE: REQUIRES TYPESCRIPT v3.8+ which in turn requires
    // angular v9, which in turn BREAKS ngx-nested-data-table
    // TODO: resume this effort after replacing nested tables with compliant
    // code & upgrading angular & typescript versions 
//
//    firebase.initializeApp(this.firebaseConfig);
//    this.firebaseUiInstance = new firebaseui.auth.AuthUI(firebase.auth());
//    this.firebaseUiInstance.start();
//
//    window['authProviderSignOut'] = function () {
//        firebase.auth().signOut();
//    }   
    
  }

  public setGoogleProfile(profile: any): void {
  	this.google_profile = profile;
  }

  public setGoogleJwt(jwt: any): void {
	this.google_jwt = jwt;
  }

  ngOnDestroy(): void {
    this.inView = false;
  }

  login(): void {
	
	//console.log('!!!!!! starting login()');
	//console.log('!!!!!! this.google_jwt: ' + this.google_jwt);
    this.errors = this.messages = [];
    this.submitted = true;

    this.legacyUserCredential.username = this.loginForm.value.email;
    this.legacyUserCredential.password = this.loginForm.value.password;
    //this.legacyUserCredential.client_token = this.userService.generateGuid();
    this.legacyUserCredential.requested_access_type = 'admin';
	//this.legacyUserCredential.jwt = this.google_jwt; // seems like a bad idea to store the jwt here... don't think we'll need it after validating.

//      .createLegacySession(this.legacyUserCredential)  // old way of creating session with credentials
    this.authenticationService
	  .createGoogleSession(this.google_jwt)
      .pipe(takeWhile(() => this.inView))
      .subscribe(
        (res: UserAuthSession) => {
          console.log('sign-in: authorized');

		  // the client_token is generated by the API
		  this.legacyUserCredential.client_token = res.client_token;

          this.userService
            .getSingleUser(res.user_id)
            .pipe(takeWhile(() => this.inView))
            .subscribe(
              (result: User) => {
                // save all login user credentials
                const loginUser = new LoginUser();
                loginUser.name = result.email;
                loginUser.fullname = result.name;
                loginUser.role = 'admin';
                loginUser.isAuthenticated = true;
                loginUser.userAuth = this.legacyUserCredential;
                loginUser.user = result;
				loginUser.picture = this.google_profile.picture;
                this.authenticationService.setLoginUser(loginUser);

                setTimeout(() => {
                  const redirect = '/pages/dashboard';
                  return this.router.navigateByUrl(redirect);
                }, 500);
              },
              err => {
                console.log('sign-in error 1: ' + err);
              }
            );
        },
        err => {
		  console.log('sign-in error 2: ' + err);
          this.showMessages.error = true;
          this.sessionExpired = false;
		  this.submitted = false; // if there's an error, reset the form so it's able to submit again
          this.errors = ['Your user is not authorized to access Novaseek Administration.  Please contact techops@novaseekresearch.com.'];
          
          // TODO: distinguish between UNAUTHORIZED and other (ie: API not responding)!!!
          
        }
      );
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
