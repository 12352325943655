import { Injectable } from '@angular/core';


//import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';



import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UserAuth } from '../authentication/user-auth';
import { AdminConfig } from './config';
import { PasswordReq } from './password-req';
import { User } from './user';
import { UserAddress } from './user-address';
import { Site } from './site';
import { WarehouseFacility } from './warehouse-facility';

@Injectable()
export class UserService {
  currentUserID = new BehaviorSubject<string>('0');
  currentMode = new BehaviorSubject<string>('AddUser');

  private config: AdminConfig = new AdminConfig();

  ////////////////////////////////////////////////////////////////////////////////////
  constructor(private httpClient: HttpClient) {}

  // change the mode for the add/modify
  changeAddMode() {
    this.currentMode.next('AddUser');
  }

  changeModifyMode(userID: string) {
    this.currentMode.next('ModifyUser');
    this.currentUserID.next(userID);
  }

  getAllUsers() {
    const allUserUrl = this.config.baseUrl + 'user';    
    return this.httpClient.get<User[]>(allUserUrl);
//      .pipe(catchError((error: any) => observableThrowError(error.json().error || 'Server error'))
//    );
  }

  getSingleUser(userid: string) {
    const userUrl = this.config.baseUrl + 'user/' + userid;
    return this.httpClient.get<User>(userUrl);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  getSingleUserAddress(userid: string) {
    const userUrl = this.config.baseUrl + 'user/' + userid + '/shipping_address';
    return this.httpClient.get<UserAddress>(userUrl);
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error')));
  }

  putSingleUser(userid: string, userdata: User) {
    const userUrl = this.config.baseUrl + 'user/' + userid;
    return this.httpClient.put<User>(userUrl, userdata);
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error'))
//        );
  }

  postSingleUserAddress(userid: string, useraddress: UserAddress) {
    const userUrl = this.config.baseUrl + 'user/' + userid + '/shipping_address';
    return this.httpClient.post<UserAddress>(userUrl, useraddress);
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error')));
  }

  createSingleUser(userdata: User) {
    const userUrl = this.config.baseUrl + 'user';
    return this.httpClient.post<User>(userUrl, userdata)
//        .pipe(catchError((error: any) =>
//            observableThrowError( error.error || 'Server error')));
  }

  createSingleUserAddress(useraddress: UserAddress) {
    const userUrl =
      this.config.baseUrl + 'user/' + useraddress.user_id + '/shipping_address';
    return this.httpClient.post<UserAddress>(userUrl, useraddress);
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error')));
  }

  deleteUser(userid: string) {
    const userUrl = this.config.baseUrl + 'user/' + userid;
    return this.httpClient.delete(userUrl);
  }

  getSites() {
    const sitesUrl = this.config.baseUrl + 'site';
    return this.httpClient.get<Site[]>(sitesUrl);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  resetPassword(userdata: UserAuth) {
    const userUrl = this.config.baseUrl + 'password_reset';
    return this.httpClient.post(userUrl, userdata);
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error')));
  }

  getPasswordRequirements() {
    const userUrl = this.config.baseUrl + 'user/user_string_resources';
    return this.httpClient.get<PasswordReq>(userUrl)
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error')));
  }

  savePasswordRequirements(value: PasswordReq) {
    const userUrl = this.config.baseUrl + 'user/user_string_resources';
    return this.httpClient.put(userUrl, value);
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error')));
  }
  
  generateGuid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }
  
/*
  private getRequestOptions() {
    let opt: RequestOptions;
    const myHeaders: Headers = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    opt = new RequestOptions({ headers: myHeaders, withCredentials: true });
    return opt;
  }
*/

}
