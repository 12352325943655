import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';


//import { HttpModule } from '@angular/http';


import { NbAuthModule, NbDummyAuthStrategy } from '@nebular/auth';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { AuthenticationService } from './authentication/authentication.service';
import { HttpInterceptor } from './http/http-interceptor';
import { ModelModule } from './model/model.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { NbSimpleRoleProvider } from './nb-simple-role-provider';
import { AnalyticsService } from './utils/analytics.service';

const socialLinks = [];

export const NB_CORE_PROVIDERS = [
    ...ModelModule.forRoot().providers,
    ...NbAuthModule.forRoot({

      strategies: [
        NbDummyAuthStrategy.setup({
          name: 'email',
          delay: 3000,
        }),
      ],
      forms: {
        login: {
          socialLinks: socialLinks,
        },
        register: {
          socialLinks: socialLinks,
        },
	   validation: {  // fields validation rules. The validations are shared between all forms.
	        password: {
	          required: true,
	          minLength: 8,
	          maxLength: 50,
	        },
	        email: {
	          required: true,
	        },
	        fullName: {
	          required: false,
	          minLength: 2,
	          maxLength: 50,
	        },
	     },
      },
    }).providers,

    NbSecurityModule.forRoot({
      accessControl: {
        guest: {
          view: '*',
        },
        user: {
          parent: 'guest',
          create: '*',
          edit: '*',
          remove: '*',
        },
      },
    }).providers,

    {
      provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
    },
    AnalyticsService,
  ];

@NgModule({
  imports: [
    CommonModule,


//    HttpModule,


    HttpClientModule
  ],
  exports: [
    NbAuthModule,
  ],
  providers: [AuthenticationService, { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true }],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    } as ModuleWithProviders;
  }
}

/*
const NB_CORE_PROVIDERS = [
  ...ModelModule.forRoot().providers,
  ...NbAuthModule.forRoot({
    providers: {
      email: {
        service: NbPasswordAuthStrategy,
        config: {
            baseEndpoint: 'https://novaseek-portal-api.isstechn.com:8150/admin/session?api_key=12345',
            login: {
              alwaysFail: false,
              rememberMe: true,
              endpoint: '/auth/login',
              method: 'post',
              redirect: {
                success: '/',
                failure: null,
              },
              defaultErrors: ['Login/Email combination is not correct, please try again.'],
              defaultMessages: ['You have been successfully logged in.'],
            },
        },
      },
    },
    forms: {

     login: {
        redirectDelay: 500, // delay before redirect after a successful login, while success message is shown to the user
        provider: 'email',  // provider id key. If you have multiple providers, or what to use your own
        rememberMe: false,   // whether to show or not the `rememberMe` checkbox
        showMessages: {     // show/not show success/error messages
          success: true,
          error: true,
        },
      },
      register: {
        redirectDelay: 0,
        showMessages: {
          success: true,
        },
      },
      requestPassword: {
        redirectDelay: 0,
        showMessages: {
          success: true,
        },
      },
      resetPassword: {
        redirectDelay: 0,
        showMessages: {
          success: true,
        },
      },
      logout: {
        redirectDelay: 0,
        alwaysFail: false,
        endpoint: '/api/auth/logout',
        method: 'delete',
        redirect: {
          success: '/',
          failure: null,
        },
        defaultErrors: ['Something went wrong, please try again.'],
        defaultMessages: ['You have been successfully logged out.'],
      },
      validation: {  // fields validation rules. The validations are shared between all forms.
        password: {
          required: true,
          minLength: 8,
          maxLength: 50,
        },
        email: {
          required: true,
        },
        fullName: {
          required: false,
          minLength: 2,
          maxLength: 50,
        },
      },
    },
  }).providers,
  AnalyticsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
*/
