import { Injectable } from '@angular/core';


//import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';



import { BehaviorSubject, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AdminConfig } from './config';
import { PatientEncounter, LabTest, Problem,
         Medication, Procedure, Specimen, Narrative} from './specimen';

@Injectable()
export class SpecimenService {
  config: AdminConfig = new AdminConfig();
  labtest: Subject<LabTest[]> = new BehaviorSubject<LabTest[]>([]);

  /////////////////////////////////////////////////////////////////////////////
  constructor(private httpClient: HttpClient) {}

  // Demographics
  getWarehouseFacility<WarehouseFacility>(site_id: string) {
    const url =
        this.config.baseUrl + 'warehouse_facility/facility_id/' + site_id;

    return this.httpClient.get(url);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  // Demographics
  getPatientDemographics(patient_code: string) {
    const url =
      this.config.baseUrl + 'warehouse_encounter/patient_code/' + patient_code;

    return this.httpClient.get<PatientEncounter>(url);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  // Lab Results
  getPatientLabResults(patient_code: string) {
    const url =
      this.config.baseUrl + 'warehouse_labtests/patient_code/' + patient_code;

    return this.httpClient.get<LabTest[]>(url);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  // Problems Results
  getPatientProblems(patient_code: string) {
    const url =
      this.config.baseUrl + 'warehouse_problems/patient_code/' + patient_code;

    return this.httpClient.get<Problem[]>(url);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  // Medications Results
  getPatientMedications(patient_code: string) {
    const url =
      this.config.baseUrl + 'warehouse_medications/patient_code/' + patient_code;

    return this.httpClient.get<Medication[]>(url);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  // Procedure Results
  getPatientProcedures(patient_code: string) {
    const url =
      this.config.baseUrl + 'warehouse_procedures/patient_code/' + patient_code;

    return this.httpClient.get<Procedure[]>(url);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  // Speciments Results
  getPatientSpecimens(patient_code: string) {
    const url =
      this.config.baseUrl + 'warehouse_specimens/patient_code/' + patient_code;

    return this.httpClient.get<Specimen[]>(url);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  getPatientNarratives(patient_code: string) {
    const url =
      this.config.baseUrl + 'warehouse_narratives/patient_code/' + patient_code;
      
    return this.httpClient.get<Narrative[]>(url);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }
}
