import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

//import { Headers, Http, RequestOptions, Response } from '@angular/http';



import { BehaviorSubject, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ShippingReport } from '../../pages/reports/shipmentreport/shipping.report.model';
import { ShippingSpecimenReport } from '../../pages/reports/shipmentreport/shipping.specimen.report.model';
import { AdminConfig } from './config';
import { LabTest } from './specimen';
import { Site } from './site';

/*
const httpOptions = {
  params: new HttpParams()
};
*/

@Injectable()
export class ReportService {
  config: AdminConfig = new AdminConfig();
  labtest: Subject<LabTest[]> = new BehaviorSubject<LabTest[]>([]);

  /////////////////////////////////////////////////////////////////////////////
//  constructor(private httpClient: HttpClient, private http: Http) {}
  constructor(private httpClient: HttpClient) {}

  getSitesByType(sitetype: string) {
    let url = this.config.baseUrl + 'site';
    sitetype = sitetype.trim();

    // Add sitetype if not empty
    const options = sitetype ?
        { params: new HttpParams().set('site_type', sitetype) } : {};

  return this.httpClient.get<Site[]>(url, options);
//    .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  getConsentReport(sitesCsv: string, startDate: string, endDate: string) {
    const url = this.config.baseUrl + 'reports/consent_report';

    const options = { params: new HttpParams()
        .set('sitesCsv', sitesCsv)
        .set('startDate', startDate)
        .set('endDate', endDate)
        };

    return this.httpClient.get<Object[]>(url, options);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  // TODO: rename/replace server resource to better describe functionality
  // (simply returns list of organization names from the shipping_address table)
  getOrganizationList() {
    const url = this.config.baseUrl + 'shipping_address/organizations';

    return this.httpClient.get<Object[]>(url);
//        .pipe(catchError((error: any) =>
//        observableThrowError(error.json().error || 'Server error')));
  }

  getBillingReport(sitesCsv: string, organization: string, startDate: string, endDate: string) {
    const url = this.config.baseUrl + 'reports/billing_report';
    const params = new HttpParams()
        .set('sitesCsv', sitesCsv)
        .set('startDate', startDate)
        .set('endDate', endDate);
    if (organization !== '') { params.set('organization', organization ); }
        
    return this.httpClient.get<Object[]>(url, { params });
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error')));
  }

  getShippingReport(sitesCsv: string, orgsPsv: string, startDate: string, endDate: string) {
    // e.g. /admin/reports/shipping_report?
    // shipment_id=128&orgsPsv=DataArts|Novaseek+Research&sitesCsv=23,75&startDate=2017-01-01&endDate=2018-01-01&api_key=12345
    // Shipment_id:  this is a specific Shipment Id you want to filter on, default is all Shipments meeting other filters.
    // sitesCsv :  this is a comma delimited list of lab site Ids for filters, default is all labs.  Same as for Billing Report
    // orgsPsv:   this is a pipe delimited list of organization names (may contain commas) for filters, default is all organizations
    // startDate, endDate:   inclusive dates  yyyy-mm-dd

    // note: currently is getting everything, issue with siteCSV, wont work if 2 or more?
    const url = this.config.baseUrl + 'reports/shipments_report';

    const params = new HttpParams();
    if (sitesCsv) {
      params.set('sitesCsv', sitesCsv);
    }
    if (orgsPsv) {
      params.set('orgsPsv', orgsPsv);
    }
    if (startDate) {
      params.set('startDate', startDate);
    }
    if (endDate) {
      params.set('endDate', endDate);
    }

    //httpOptions.params = params;

    return this.httpClient.get<ShippingReport[]>(url, { params } );
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error')));
  }

  getShippingDetailReport(shipmentId: string) {
    // e.g. /admin/reports/shipping_specimen_report?shipment_id=128&api_key=12345
    // Shipment_id:  identifies the shipment you are drilling down on, defaults to ALL (not recommended!)
    const url = this.config.baseUrl + 'reports/shipment_specimens_report';
    const params = new HttpParams();
    if (shipmentId) { params.set('shipment_id', shipmentId ); }

    return this.httpClient.get<ShippingSpecimenReport[]>(url, { params } );
//        .pipe(catchError((error: any) =>
//            observableThrowError(error.json().error || 'Server error')));
  }
}
