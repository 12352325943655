// DEPRECATED - use model/user-credential instead
export class UserAuth {
  username: string;
  password: string;
  client_token: string;
  requested_access_type: string;
  impersonator_username: string;
  jwt: string;
  constructor() {
    this.username = '';
    this.password = '';
    this.client_token = '';
    this.requested_access_type = 'admin';
    this.impersonator_username = '';
	this.jwt = '';
  }
}
